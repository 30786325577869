import { useMutationAbstract } from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";

type TxnRespType = {
  RRN: string;
  ProcessedDateTime: string;
  ResponseText: string;
  ResponseCode: string;
  TxnNumber: string;
  BankResponseCode: string;
};

type APIResponseType = {
  ResponseCode: number;
  ResponseText: string;
};

export const useSubmitResultKey = () =>
  useMutationAbstract<
    {
      APIResponse: APIResponseType;
      TxnResp: TxnRespType;
    },
    {
      APIResponse: APIResponseType;
      TxnResp: null;
    },
    {
      ResultKey: string;
      "g-recaptcha-response": string;
      transaction_for: string;
    }
  >({
    mutationFn: (data) =>
      axios.post("/api/submit-result-key", data).then((res) => res.data),
  });

export const useSubmitEft = () =>
  useMutationAbstract({
    mutationFn: (data) =>
      axios.post("/api/submit-eft", data).then((res) => res.data),
  });
