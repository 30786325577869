import { useSnackbar } from "notistack";
import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useFormik } from "formik";
import { UseMutationOptions } from "@tanstack/react-query/src/types";
import axios, { AxiosError } from "axios";
import {get} from "lodash";

type useDefaultErrorHandlerType = Pick<
  ReturnType<typeof useFormik>,
  "setFieldError"
>;

export function useDefaultErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();

  const handleAxiosError = (error: AxiosError<{ message?: string }>) => {
    const { response } = error;
    if (!response) {
      console.log("No response");
      return;
    }

    const {
      status,
      data: { message },
        headers,
    } = response;

    const responseRequestId = get(headers, "x-request-id");

    const msg = message ?? `Something went wrong. ${responseRequestId ? `Please contact us with this reference #: ${responseRequestId}` : ''}`;
    enqueueSnackbar(msg, {
      variant: "error",
      preventDuplicate: true,
      persist: true,
      key: status,
    });
  };

  const handleStockError = (error: Error) => {
    console.error(error);
    const msg = error?.message ?? "Something went wrong.";
    enqueueSnackbar(msg, {
      variant: "error",
      preventDuplicate: true,
    });
  };

  return (err: any) => {
    if (axios.isAxiosError(err) && err?.response) {
      handleAxiosError(err as AxiosError<{ message?: string }>);
    } else if (err instanceof Error) {
      handleStockError(err);
    } else {
      console.error(err);
      enqueueSnackbar(
        "Something went wrong. We are notified. You may try again or contact us if the error persists.",
        {
          variant: "error",
          preventDuplicate: true,
        }
      );
    }
  };
}

type MutationOptionsType<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  invalidateQueryKey?: QueryKey;
};

export type MutationResultType<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
> = UseMutationResult<TData, TError, TVariables, TContext>;

export function useMutationAbstract<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(mutationOptions: MutationOptionsType<TData, TError, TVariables, TContext>) {
  const { invalidateQueryKey, ...theRest } = mutationOptions;
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>({
    onSuccess: async () => {
      if (invalidateQueryKey) {
        await queryClient.invalidateQueries(invalidateQueryKey);
      }
    },
    onError: useDefaultErrorHandler(),
    ...theRest,
  });
}

// export const useMutationFormAbstract = (
//   mutationOptions: MutationOptionsType,
//   formOptions: FormikConfig<any>
// ) => {
//   const formik = useFormik(formOptions);
//   const { setFieldError, resetForm } = formik;
//   // const onError = useDefaultErrorHandler(setFieldError);
//
//   const mutation = useMutationAbstract(mutationOptions);
//
//   return {
//     formik,
//     mutation,
//   };
// };
