export const mainApiBaseUrl = import.meta.env.ALDRIN_ERP_MAIN_API_URL ?? "";

export const reCaptchaSiteKey =
  import.meta.env.RECAPTCHA_SITE_KEY ??
  "6LcXg44jAAAAAEZZg0XXhvLrSrEMRAhy-59HQHBm";

export const rollbarConfig = {
  accessToken:
    import.meta.env.ROLLBAR_ACCESS_TOKEN ?? "1b78b60023124c4ab882ae1a037b08fe",
  // environment: import.meta.env.NODE_ENV ?? "development",
  transmit: import.meta.env.ROLLBAR_TRANSMIT === "true",
};
