import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { UploadAttachments } from "./UploadAttachments";

interface Props {
  is_eft?: boolean;
}

const OrderConfirmationMessage = ({ is_eft }: Props) => {
  const [searchParams] = useSearchParams();
  const transaction_for = searchParams.get("transaction_for");
  const order_uuid = searchParams.get("order_uuid");
  const company_image = searchParams.get("company_image");

  if (transaction_for !== "deposit" && !is_eft) {
    return (
      <>
        {!!company_image && <img src={company_image} alt="logo" width="100%" />}

        <Typography variant="h3" gutterBottom mt={6}>
          Thank you, your payment has been received.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="h3" gutterBottom mb={6}>
        YOUR ORDER HAS BEEN RECEIVED!
      </Typography>

      <Typography variant="h4" gutterBottom>
        THANK YOU FOR CHOOSING ONESOLOMONS FOR YOUR HOME IMPROVEMENT PROJECT.
      </Typography>

      <Typography variant="h4" gutterBottom>
        SO WHAT HAPPENS NEXT?
      </Typography>

      {!!is_eft && !!order_uuid && (
        <Box mt={6}>
          <Typography variant="body1" gutterBottom textAlign="center">
            Upload your remittance or purchase order below
          </Typography>

          <UploadAttachments />
        </Box>
      )}

      <Typography variant="body1">
        Your order will be processed by our accounts department within one or
        two days of us receiving it. If you have already provided credit card
        details for your deposit or have made a transfer via EFT a receipt will
        be available on your order page as soon as the funds are received. If
        you have not yet provided us with payment details we will get in touch
        to confirm those with you.{" "}
        <strong>
          Please note that fabric changes for blinds and awnings cannot be
          accepted after the order is processed.
        </strong>
        <br />
        <br />
        You can download a completed copy of any QBCC documentation that may be
        applicable from your order page.
        <br /> <br />
        Once your order is processed it will be quantified for materials and
        forwarded to our fitting office who will contact you within two (2)
        weeks with a date & time for your check measure (if required) and
        subsequent installation. For most types of blinds a check measure is not
        required. <br />
        <br />
        Your order will then be completed by our factory and your installation
        date confirmed by our fitting office.
        <br />
        <br />
        Please note that for QBCC contracts your pre-delivery payment (second
        payment) is due prior to installation. For all other orders your final
        payment is due once your products have been manufactured. <br />
      </Typography>

      <ul>
        <li>
          If paying by credit card, we will request your permission to debit the
          amount the day prior to installation.
        </li>
        <li>
          If paying by direct deposit, we request the transfer be made three
          days prior to installation so that it shows up in our banking the day
          before installation.
        </li>
      </ul>

      <Typography variant="body1">
        The office will discuss this with you when your installation date is
        confirmed. <br />
        <br />
      </Typography>

      <Typography variant="h4" gutterBottom>
        YOUR SECURITY IS OUR TOP PRIORITY
      </Typography>

      <Typography variant="body1">
        No credit card details are retained after completion of order. The
        paperwork is placed in a Shred-X secure bin which is professionally
        shredded. No credit card details are retained on our computer system.
        <br />
        <br />
      </Typography>

      <Typography variant="h4" gutterBottom>
        YOUR FEEDBACK IS IMPORTANT TO US
      </Typography>

      <Typography variant="body1">
        You can find our full product warranties and care instructions on your
        order page.
        <br />
        <br />
        You must register your product warranty within 30 days of the
        installation. <br />
        <br />
        Here you will also find links to leave reviews and a quick survey of
        your experience with OneSolomons. Please take a moment to complete this
        and let us know how we can improve our service to you.
        <br />
        <br />
        We are ready to assist you so if you have any further questions or
        queries simply call 1300 304 300 and have your job number handy or email
        address handy.
      </Typography>
    </>
  );
};

export default OrderConfirmationMessage;
