import { Box } from "@mui/material";
import Uppy, { SuccessResponse, UppyFile } from "@uppy/core";
import { v4 } from "uuid";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

import React, { useCallback, useEffect, useState } from "react";
import XHRUpload from "@uppy/xhr-upload";
import Dashboard from "@uppy/dashboard";
import { mainApiBaseUrl } from "../../config";
import { useSearchParams } from "react-router-dom";

export const UploadAttachments = () => {
  const [searchParams] = useSearchParams();
  const order_uuid = searchParams.get("order_uuid");

  const [uppy] = useState(() =>
    new Uppy({
      autoProceed: true,
    }).use(XHRUpload, {
      endpoint: `${mainApiBaseUrl}/api/order/${order_uuid}/add-file-attachment-public`,
    })
  );

  useEffect(() => {
    uppy.use(Dashboard, {
      inline: true,
      target: "#uppy-dashboard",
      proudlyDisplayPoweredByUppy: false,
      showLinkToFileUploadResult: true,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: false,
      disableStatusBar: true,
      disableInformer: true,
      height: 250,
    });
  }, [uppy]);

  const onFileAdded = useCallback((file: UppyFile) => {
    uppy.setFileMeta(file.id, {
      uuid: v4(),
    });
  }, []);

  // const _onUploadSuccess = useCallback(
  //   (file: UppyFile | undefined, response: SuccessResponse) => {
  //     if (!file) {
  //       return;
  //     }
  //
  //     onUploadSuccess && onUploadSuccess(file, response);
  //   },
  //   [onUploadSuccess]
  // );

  useEffect(() => {
    uppy.on("file-added", onFileAdded);
    // uppy.on("upload-success", _onUploadSuccess);

    return () => {
      uppy.off("file-added", onFileAdded);
      // uppy.off("upload-success", _onUploadSuccess);
    };
  }, [onFileAdded]);

  return (
    <Box my={6}>
      {/*<DragDropReact uppy={uppy} allowMultipleFiles height={150} />*/}
      <div id="uppy-dashboard"></div>
    </Box>
  );
};
