import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import { ThemeProvider } from "./contexts/ThemeContext";
import { RecoilRoot } from "recoil";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";
import { rollbarConfig } from "./config";

const container = document.getElementById("root");
const root = createRoot(container!);

const FallbackUI = () => <div>Oops, something went wrong</div>;

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <RecoilRoot>
        <RollbarProvider
          config={{
            ...rollbarConfig,
            captureUncaught: true,
            captureUnhandledRejections: true,
            reportLevel: "info",
            autoInstrument: true,
          }}
        >
          {/*@ts-ignore*/}
          <ErrorBoundary fallbackUI={FallbackUI}>
            <App />
          </ErrorBoundary>
        </RollbarProvider>
      </RecoilRoot>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
