import axios from "../../utils/axios";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCustomerPaymentInfo } from "./index";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { isString } from "lodash";
import { FormikHelpers } from "formik/dist/types";
import { useSubmitEft } from "../../api/Api";
import { useRollbar } from "@rollbar/react";
import qs from "qs";
import { toDecimal } from "dinero.js";
import { dineroAUDFromFloat } from "../../utils/dineroAUDFromFloat";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

export type CustomerInfoType = {
  firstName: string;
  lastName: string;
  email: string;
  merchantReference: string;
  totalQuoteAmount: number;
  amount?: number;
  paymentMethod: "credit-card" | "eft";
  order_uuid?: string; // optional since there are orders not coming from AERP
  transaction_for?: string;
  company_image?: string;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required().max(250),
  merchantReference: Yup.string().required("Required").max(50),
  totalQuoteAmount: Yup.number().required("Required").min(1),
  amount: Yup.number().optional(),
  paymentMethod: Yup.string().oneOf(["credit-card", "eft"]).required(),
  order_uuid: Yup.string().uuid().optional(),
  company_image: Yup.string().url().optional(),
  transaction_for: Yup.string()
    .optional()
    .oneOf(["deposit", "pre-delivery", "final"]),
});

const CustomerInfoForm = () => {
  const navigate = useNavigate();
  const { setValues } = useCustomerPaymentInfo();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: submitEft } = useSubmitEft();
  const rollbar = useRollbar();

  const [searchParams] = useSearchParams();
  const parsedSearchParams = qs.parse(searchParams.toString());

  const initialValuesFromMain = parsedSearchParams?.initialValues as Record<
    string,
    string
  >;

  const initialValues: CustomerInfoType = {
    firstName: "",
    lastName: "",
    email: "",
    merchantReference: "",
    paymentMethod: "credit-card",
    totalQuoteAmount: 0,
    amount: 0,
    transaction_for: "deposit",
    ...initialValuesFromMain,
  };

  const transactionForDeposit = initialValues?.transaction_for === "deposit";

  const handleSubmit = async (
    values: CustomerInfoType,
    helpers: FormikHelpers<any>
  ) => {
    const valuesStripped = validationSchema.cast(values, {
      stripUnknown: true,
    });

    switch (values.paymentMethod) {
      case "credit-card":
        await handleSubmitCreditCard(valuesStripped, helpers);
        break;
      case "eft":
        await handleSubmitEFT(valuesStripped, helpers);
        break;
    }
  };

  const handleSubmitCreditCard = async (
    values: CustomerInfoType,
    { resetForm, setErrors, setStatus, setSubmitting }: FormikHelpers<any>
  ) => {
    if (!executeRecaptcha) {
      rollbar.error(
        "handleSubmitCreditCard() was called even though executeRecaptcha is not yet available"
      );
      console.error("executeRecaptcha not yet available");
      return;
    }

    try {
      rollbar.captureEvent(
        {
          message: "create-auth-key",
        },
        "info"
      );

      const { data } = await axios.post("/api/create-auth-key", {
        ...values,
        "g-recaptcha-response": await executeRecaptcha(
          "CustomerInfoForm_handleSubmitCreditCard"
        ),
      });
      const { AuthKey, TestMode, TestModeReason } = data;

      setValues((prevValue) => ({
        ...prevValue,
        ...values,
        authKey: AuthKey,
        testMode: TestMode,
        testModeReason: TestModeReason,
      }));

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);

      navigate("/payment");
    } catch (error: any) {
      let msg = "Something went wrong. Please try a again.";
      if (isString(error)) msg = error;
      else if (error?.message) msg = error?.message;

      setStatus({ sent: false });
      setErrors({ submit: msg });
      setSubmitting(false);
    }
  };

  const handleSubmitEFT = async (
    values: CustomerInfoType,
    { resetForm, setErrors, setStatus, setSubmitting }: FormikHelpers<any>
  ) => {
    if (!executeRecaptcha) {
      rollbar.error(
        "handleSubmitEFT() was called even though executeRecaptcha is not yet available"
      );
      console.error("executeRecaptcha not yet available");
      return;
    }

    await submitEft({
      ...values,
      "g-recaptcha-response": await executeRecaptcha(
        "CustomerInfoForm_handleSubmitEFT"
      ),
    });
    navigate(`/eft?order_uuid=${initialValues?.order_uuid}`);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card my={6}>
          {transactionForDeposit && (
            <>
              <Typography component="h1" variant="h1" align="center">
                We're almost there!
              </Typography>

              <Typography variant="h5" align="center" mt={5}>
                Are you ready to add that 'wow' factor to your space?
              </Typography>
            </>
          )}

          {!transactionForDeposit && !!initialValues?.company_image && (
            <img src={initialValues?.company_image} alt="logo" width="100%" />
          )}

          <CardContent>
            {/* @ts-ignore */}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {/* @ts-ignore */}
                {errors.submit}
              </Alert>
            )}

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  margin="normal"
                />
                {transactionForDeposit && (
                  <Typography variant="body2" gutterBottom>
                    “I have read and agree to the terms and conditions contained
                    within and authorise the work to go ahead as outlined on the
                    order number below for the specified amount”
                  </Typography>
                )}

                <TextField
                  name="merchantReference"
                  label="Quote Number"
                  value={values.merchantReference}
                  error={Boolean(
                    touched.merchantReference && errors.merchantReference
                  )}
                  fullWidth
                  helperText={
                    touched.merchantReference && errors.merchantReference
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={!!initialValuesFromMain?.merchantReference}
                  type="merchantReference"
                  variant="outlined"
                  margin="normal"
                />

                {transactionForDeposit && (
                  <>
                    <TextField
                      name="totalQuoteAmount"
                      label="Total Quote Amount"
                      value={toDecimal(
                        dineroAUDFromFloat(Number(values.totalQuoteAmount) || 0)
                      )}
                      placeholder="$AUD"
                      disabled={!!initialValuesFromMain?.totalQuoteAmount}
                      error={Boolean(
                        touched.totalQuoteAmount && errors.totalQuoteAmount
                      )}
                      fullWidth
                      helperText={
                        touched.totalQuoteAmount && errors.totalQuoteAmount
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      variant="outlined"
                      margin="normal"
                    />

                    <Typography variant="body1" align="justify" my={3}>
                      All orders are subject to check measure. You are
                      responsible for organising any electrical work. You accept
                      full responsibility for colour and fabric selection. No
                      changes can be made after the order is placed.
                    </Typography>
                  </>
                )}

                <FormControl
                  error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="normal"
                >
                  <FormLabel id="radio-btns-payment-method">
                    {transactionForDeposit
                      ? "Deposit to be paid by:"
                      : "Amount to be paid by:"}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-btns-payment-method"
                    name="paymentMethod"
                    value={values.paymentMethod}
                  >
                    <FormControlLabel
                      value="credit-card"
                      control={<Radio />}
                      label="Credit Card"
                    />

                    {transactionForDeposit && (
                      <FormControlLabel
                        value="eft"
                        control={<Radio />}
                        label="EFT (use your job number as the reference)"
                      />
                    )}
                  </RadioGroup>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  margin="normal"
                >
                  {transactionForDeposit ? "Confirm Order" : "Confirm"}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default CustomerInfoForm;
